import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CustomCell from "components/common/Grid/custom-cell";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

export const Columns = (t): ExtendedGridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("market_will_pay_name"),
      width: 250,
      editable: false,
      hide: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params} />
      )
    },
    {
      field: "marketSourceDisplayName",
      headerName: t("market_will_pay_market_source_display_name"),
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params} />
      )
    },
    {
      field: "marketDisplayName",
      headerName: t("market_will_pay_market_display_name"),
      width: 250,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params} />
      )
    },
    {
      field: "marketPriceConfigurationName",
      headerName: t("market_will_pay_price_configuration"),
      width: 250,
      editable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params} />
      )
    },
    {
      field: "results",
      headerName: t("market_will_pay_combinations"),
      width: 100,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params}>
          <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {params.row.results?.map((result, i) => (
              <ListItem disableGutters disablePadding key={i}>
                <ListItemText primary={result} />
              </ListItem>
            ))}
          </List>
        </CustomCell>
      )
    },
    {
      field: "unitPrice",
      headerName: t("market_will_pay_unit_price"),
      width: 100,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell params={params}>
          <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {params.row.unitPrice?.map((value, i) => (
              <ListItem disableGutters disablePadding key={i}>
                <ListItemText primary={value.toFixed(2)} />
              </ListItem>
            ))}
          </List>
        </CustomCell>
      )
    },
    {
      field: "unbrokenPrice",
      headerName: t("market_will_pay_unbroken_price"),
      width: 100,
      editable: false,
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <List dense={true} sx={{ pt: 0, pb: 0 }}>
            {params.row.unbrokenPrice?.map((value, i) => (
              <ListItem disableGutters disablePadding key={i}>
                <ListItemText primary={value.toFixed(9)} />
              </ListItem>
            ))}
          </List>
        </Box>
      )
    }
  ];
};
export default Columns;
