/* eslint-disable complexity */
import React from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, LoadingButton } from "@mui/lab";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { Formik, Field } from "formik";
import { prism } from "@tsg/1st-grpc-web";
import { useDataApi } from "hooks/api/data/DataAPI";
import { DATA_COMPETITION_KEY } from "common/QueryKeys";
import ForeignIdentifier from "../../../common/ForeignId/foreign-id";
import { buttonStyle } from "components/style/app-style";
import DateTimeInput from "components/common/Input/date-time-input/date-time-input";
import Autocomplete from "@mui/material/Autocomplete";
import { DropdownInput } from "components/common/Input";

interface Props {
  row?: prism.v1.data.ICompetition;
  handleSuccess?: (text: string) => void;
  handleError?: (text: string) => void;
}

function CompetitionUpsert({ row, handleSuccess, handleError }: Props) {
  const { t } = useTranslation();
  const {
    useCreateCompetition,
    useUpdateCompetition,
    useListFixtureSources,
    useListFacilities,
    useListLeagues,
    useListSports
  } = useDataApi();
  const queryClient = useQueryClient();

  const upsertCallback = successMessage => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [DATA_COMPETITION_KEY] });
        handleSuccess(t(successMessage));
      },
      onError: errorResponse => {
        handleError(errorResponse?.message);
      }
    };
  };
  const { mutate: mutateAdd, isPending: addIsLoading } = useCreateCompetition(
    upsertCallback("competition_add_success_message")
  );
  const { mutate: mutateUpdate, isPending: updateIsLoading } =
    useUpdateCompetition(upsertCallback("competition_update_success_message"));
  const [minDate, setMinDate] = React.useState<Date>(new Date());
  const { data: dataFixtureSourcesData } = useListFixtureSources(
    DATA_COMPETITION_KEY,
    {
      pagingOptions: {
        maxResults: 1000
      }
    }
  );
  const fixtureSources = dataFixtureSourcesData?.fixtureSources || [];

  const { data: facilitiesData } = useListFacilities(DATA_COMPETITION_KEY, {
    filter: [{}],
    pagingOptions: {
      maxResults: 1000
    }
  });
  const facilities = facilitiesData?.facilities || [];

  const { data: leagueData } = useListLeagues(DATA_COMPETITION_KEY, {
    filter: [{}]
  });
  const leagues = leagueData?.leagues || [];

  const { data: sportData } = useListSports(DATA_COMPETITION_KEY, {});
  const sports = sportData?.sports || [];

  return (
    <Box sx={{ padding: "16px 25px" }}>
      <Typography variant="h6">
        {row ? t("update") : t("add")} {t("competition")}
      </Typography>
      <Box sx={{ marginTop: "26px", "& .MuiTextField-root": { mb: 2 } }}>
        <Formik
          initialValues={{
            displayName: row?.displayName?.value || "",
            foreignIds: row?.foreignIds || [],
            sourceName: row?.sourceName || "",
            name: row?.name || "",
            racingCompetition: row?.racingCompetition.raceNumber,
            scheduledStartTime: row?.scheduledStartTime,
            estimatedStartTime: row?.estimatedStartTime,
            approvedTime: row?.approvedTime,
            actualStartTime: row?.actualStartTime,
            resetTime: row?.resetTime,
            cancelledTime: row?.cancelledTime,
            officialedTime: row?.officialedTime,
            unofficialedTime: row?.unofficialedTime,
            resultedTime: row?.resultedTime,
            type: row?.type,
            sportName: row?.sportName,
            facilityName: row?.facilityName,
            leagueName: row?.leagueName,
            title: row?.title,
            fixtureSource: row?.sourceName,
            eventDateDay: row?.eventDate.day,
            eventDateMonth: row?.eventDate.month,
            eventDateYear: row?.eventDate.year,
            state:
              row?.state ||
              prism.v1.data.CompetitionEnums.State.STATE_INCOMPLETE
          }}
          onSubmit={data => {
            if (row) {
              const updateCompetition = {
                competition: {
                  name: data.name,
                  displayName: { value: data.displayName },
                  sourceName: data.sourceName,
                  foreignIds: data.foreignIds,
                  racingCompetition: { raceNumber: data.racingCompetition },
                  type: data.type,
                  scheduledStartTime: data?.scheduledStartTime,
                  estimatedStartTime: data?.estimatedStartTime,
                  approvedTime: data?.approvedTime,
                  actualStartTime: data?.actualStartTime,
                  resetTime: data?.resetTime,
                  cancelledTime: data?.cancelledTime,
                  officialedTime: data?.officialedTime,
                  unofficialedTime: data?.unofficialedTime,
                  resultedTime: data?.resultedTime,
                  title: data?.title,
                  facilityName: data?.facilityName,
                  leagueName: data?.leagueName,
                  sportName: data?.sportName
                },
                fieldMask: {
                  paths: [
                    "displayName",
                    "Title",
                    "Facility",
                    "ScheduledStartTime",
                    "EstimatedStartTime",
                    "foreignIds",
                    "RaceNumber",
                    "League",
                    "Sport"
                  ]
                }
              };
              mutateUpdate(updateCompetition);
            } else {
              const addCompetition = {
                competition: {
                  sourceName: data.sourceName,
                  displayName: { value: data.displayName },
                  scheduledStartTime: data.scheduledStartTime,
                  estimatedStartTime: data.estimatedStartTime,
                  type: prism.v1.data.CompetitionEnums.Type.TYPE_UNSPECIFIED,
                  state: prism.v1.data.CompetitionEnums.State.STATE_INCOMPLETE,
                  competitors: {},
                  title: data.title,
                  facilityName: data.facilityName,
                  approvedTime: data.approvedTime,
                  actualStartTime: data.actualStartTime,
                  resetTime: data.resetTime,
                  cancelledTime: data.cancelledTime,
                  officialedTime: data.officialedTime,
                  unofficialedTime: data.unofficialedTime,
                  resultedTime: data.resultedTime,
                  foreignIds: data.foreignIds,
                  racingCompetition: { raceNumber: data.racingCompetition },
                  leagueName: data.leagueName,
                  eventDate: {
                    year: data.eventDateYear,
                    month: data.eventDateMonth,
                    day: data.eventDateDay
                  }
                }
              };
              mutateAdd(addCompetition);
            }
          }}
        >
          {formik => (
            <form noValidate onSubmit={formik.handleSubmit}>
              <Grid container alignItems="center">
                <TextField
                  id="displayName"
                  label={t("competition_display_name")}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="displayName"
                  onChange={formik.handleChange}
                  defaultValue={row?.displayName.value}
                  error={
                    formik.touched.displayName &&
                    Boolean(formik.errors.displayName)
                  }
                />
                <Autocomplete
                  multiple={false}
                  options={fixtureSources}
                  fullWidth
                  getOptionLabel={option =>
                    option.name ||
                    fixtureSources.find(p => p.name === String(option))?.name ||
                    String(option)
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("sourceName", newValue?.name);
                  }}
                  defaultValue={row?.sourceName}
                  disabled={Boolean(row?.sourceName)}
                  renderInput={params => (
                    <DropdownInput
                      {...params}
                      formik={formik}
                      field="parent"
                      label={t("competition_source")}
                      placeholder={t("competition_source")}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
                <TextField
                  id="title"
                  label={t("competition_title")}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="title"
                  onChange={formik.handleChange}
                  defaultValue={row?.title}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                />
                <Autocomplete
                  multiple={false}
                  options={sports}
                  fullWidth
                  getOptionLabel={option =>
                    option.name ||
                    sports.find(p => p.name === String(option))?.name ||
                    String(option)
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("sportName", newValue?.name);
                  }}
                  defaultValue={row?.sportName}
                  disabled={Boolean(row?.sportName)}
                  renderInput={params => (
                    <DropdownInput
                      {...params}
                      formik={formik}
                      field="parent"
                      label={t("competition_sport_name")}
                      placeholder={t("competition_sport_name")}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
                <Autocomplete
                  multiple={false}
                  options={leagues}
                  fullWidth
                  getOptionLabel={option =>
                    option.name ||
                    leagues.find(p => p.name === String(option))?.name ||
                    String(option)
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("leagueName", newValue?.name);
                  }}
                  defaultValue={row?.leagueName}
                  disabled={Boolean(row?.leagueName)}
                  renderInput={params => (
                    <DropdownInput
                      {...params}
                      formik={formik}
                      field="parent"
                      label={t("competition_league_name")}
                      placeholder={t("competition_league_name")}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
                <Autocomplete
                  multiple={false}
                  options={facilities}
                  fullWidth
                  getOptionLabel={option =>
                    option.name ||
                    facilities.find(p => p.name === String(option))?.name ||
                    String(option)
                  }
                  onChange={(event, newValue) => {
                    formik.setFieldValue("facilityName", newValue?.name);
                  }}
                  defaultValue={row?.facilityName}
                  disabled={Boolean(row?.facilityName)}
                  renderInput={params => (
                    <DropdownInput
                      {...params}
                      formik={formik}
                      field="parent"
                      label={t("competition_facility")}
                      placeholder={t("competition_facility")}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
                <TextField
                  id="racingCompetition"
                  label={t("competition_racing")}
                  variant="outlined"
                  size="medium"
                  fullWidth
                  name="racingCompetition"
                  onChange={formik.handleChange}
                  defaultValue={row?.racingCompetition?.raceNumber}
                  error={
                    formik.touched.racingCompetition &&
                    Boolean(formik.errors.racingCompetition)
                  }
                />
                {/* /* to bed included at a later time */}
                {/* <TextField
                  id="state"
                  select
                  label={t("competition_state")}
                  defaultValue={row?.state}
                  fullWidth
                  disabled={row}
                >
                  <MenuItem value={0}>STATE_UNSPECIFIED</MenuItem>
                  <MenuItem value={1}>STATE_INCOMPLETE</MenuItem>
                  <MenuItem value={2}>STATE_APPROVED</MenuItem>
                  <MenuItem value={3}>STATE_LIVE</MenuItem>
                  <MenuItem value={4}>STATE_CANCELLED</MenuItem>
                  <MenuItem value={5}>STATE_RESULTED</MenuItem>
                  <MenuItem value={6}>STATE_OFFICIAL</MenuItem>
                </TextField> */}
                {/* <TextField
                  size="medium"
                  label={t("competition_type")}
                  name="commission.roundMethod"
                  fullWidth
                  select
                  required
                  onChange={formik.handleChange}
                  defaultValue={row?.type}
                  disabled={row}
                >
                  {Object.values(prism.v1.data.CompetitionEnums.Type)?.map(
                    (v, i) => {
                      return (
                        <MenuItem key={i} value={v}>
                          {t(
                            `competition_type_enum.${prism.v1.data.CompetitionEnums.Type[v]}`
                          )}
                        </MenuItem>
                      );
                    }
                  )}
                </TextField> */}
                <Grid container>
                  <Grid item xs={3}>
                    <TextField
                      id="eventDateMonth"
                      label={t("competition_event_month")}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      name="eventDateMonth"
                      onChange={formik.handleChange}
                      disabled={row}
                      defaultValue={row?.eventDate ? row.eventDate.month : "mm"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="eventDateDay"
                      label={t("competition_event_day")}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      name="eventDateDay"
                      onChange={formik.handleChange}
                      disabled={row}
                      defaultValue={row?.eventDate ? row?.eventDate.day : "dd"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="eventDateYear"
                      label={t("competition_event_year")}
                      variant="outlined"
                      size="medium"
                      fullWidth
                      name="eventDateYear"
                      onChange={formik.handleChange}
                      disabled={row}
                      defaultValue={
                        row?.eventDate ? row?.eventDate.year : "yyyy"
                      }
                    />
                  </Grid>
                </Grid>
                <DateTimeInput
                  formik={formik}
                  field="scheduledStartTime"
                  name="scheduledStartTime"
                  error={
                    formik.touched.scheduledStartTime &&
                    Boolean(formik.errors.scheduledStartTime)
                  }
                  helperText={
                    formik.touched.scheduledStartTime &&
                    formik.errors.scheduledStartTime
                  }
                  label={t("competition_sch_start_time")}
                  value={row?.scheduledStartTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="estimatedStartTime"
                  name="estimatedStartTime"
                  error={
                    formik.touched.estimatedStartTime &&
                    Boolean(formik.errors.estimatedStartTime)
                  }
                  helperText={
                    formik.touched.estimatedStartTime &&
                    formik.errors.estimatedStartTime
                  }
                  label={t("competition_est_start_time")}
                  value={row?.estimatedStartTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="approvedTime"
                  name="approvedTime"
                  disabled={row}
                  error={
                    formik.touched.approvedTime &&
                    Boolean(formik.errors.approvedTime)
                  }
                  helperText={
                    formik.touched.approvedTime && formik.errors.approvedTime
                  }
                  label={t("competition_approved")}
                  value={row?.approvedTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="actualStartTime"
                  name="actualStartTime"
                  disabled={row}
                  error={
                    formik.touched.actualStartTime &&
                    Boolean(formik.errors.actualStartTime)
                  }
                  helperText={
                    formik.touched.actualStartTime &&
                    formik.errors.actualStartTime
                  }
                  label={t("competition_actual_time")}
                  value={row?.actualStartTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="resetTime"
                  name="resetTime"
                  disabled={row}
                  error={
                    formik.touched.resetTime && Boolean(formik.errors.resetTime)
                  }
                  helperText={
                    formik.touched.resetTime && formik.errors.resetTime
                  }
                  label={t("competition_reset")}
                  value={row?.resetTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="cancelledTime"
                  name="cancelledTime"
                  disabled={row}
                  error={
                    formik.touched.cancelledTime &&
                    Boolean(formik.errors.cancelledTime)
                  }
                  helperText={
                    formik.touched.cancelledTime && formik.errors.cancelledTime
                  }
                  label={t("competition_canceled")}
                  value={row?.cancelledTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="officialedTime"
                  name="officialedTime"
                  disabled={row}
                  error={
                    formik.touched.officialedTime &&
                    Boolean(formik.errors.officialedTime)
                  }
                  helperText={
                    formik.touched.officialedTime &&
                    formik.errors.officialedTime
                  }
                  label={t("competition_official")}
                  value={row?.officialedTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="unofficialedTime"
                  name="unofficialedTime"
                  disabled={row}
                  error={
                    formik.touched.unofficialedTime &&
                    Boolean(formik.errors.unofficialedTime)
                  }
                  helperText={
                    formik.touched.unofficialedTime &&
                    formik.errors.unofficialedTime
                  }
                  label={t("competition_unofficial")}
                  value={row?.unofficialedTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <DateTimeInput
                  formik={formik}
                  field="resultedTime"
                  name="resultedTime"
                  disabled={row}
                  error={
                    formik.touched.resultedTime &&
                    Boolean(formik.errors.resultedTime)
                  }
                  helperText={
                    formik.touched.resultedTime && formik.errors.resultedTime
                  }
                  label={t("competition_resulted")}
                  value={row?.resultedTime}
                  minDate={new Date()}
                  onChange={dt => setMinDate(dt)}
                />
                <Field name="foreignIds" component={ForeignIdentifier} />
              </Grid>
              {/* //competitors */}
              <LoadingButton
                variant="contained"
                fullWidth
                disableElevation
                sx={row ? buttonStyle() : null}
                type="submit"
                size="large"
                loading={addIsLoading || updateIsLoading}
                loadingPosition="start"
                disabled={!(formik.isValid && formik.dirty)}
              >
                {row ? t("update_button_text") : t("add_button_text")}
              </LoadingButton>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default CompetitionUpsert;
