import React, { useMemo } from "react";
import TableContainer from "@mui/material/TableContainer";
import { convertMoneyToDouble } from "common/helper";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { prism } from "@tsg/1st-grpc-web";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { stronachgroup } from "@tsg/1st-grpc-js/lib";
import IWageringSource = stronachgroup.first.prism.v1.transaction.IWageringSource;

interface IWageringSourceTotal
  extends prism.v1.transaction.MarketTotal.IWageringSourceTotal {
  wageringSourceNested: IWageringSource;
}

interface OwnProps {
  matrixData: Record<string, IWageringSourceTotal>;
  selectedWageringSourceName?: string;
}

const Container = styled(Box)({
  width: "100%"
});

const ListItemCustom = styled(ListItem, {
  name: "ListItemCustom",
  slot: "Root"
})({
  display: "flex",
  padding: 0,
  height: "53px"
});

const ListItemTextCustom = styled(Box, {
  name: "ListItemTextCustom",
  slot: "Root"
})({
  flex: 1,
  borderTop: "1px solid",
  borderRight: "1px solid",
  height: "100%",
  display: "flex",
  paddingLeft: "16px",
  alignItems: "center"
});

const ComboNumber = styled(Box, {
  name: "ComboNumber",
  slot: "Root"
})({
  borderTop: "1px solid",
  borderLeft: "1px solid",
  borderRight: "1px solid",
  display: "flex",
  height: "100%",
  padding: "1rem",
  alignItems: "center",
  justifyContent: "center"
});

const MoneyMatrix = ({ matrixData, selectedWageringSourceName }: OwnProps) => {
  const theme = useTheme();
  const combos: IWageringSourceTotal[] = useMemo(() => {
    return Object.values(matrixData).flat().filter(Boolean);
  }, [selectedWageringSourceName, matrixData]);

  const renderList = () => {
    return combos.map((combo, idx) => {
      const borderBottom =
        idx === combos.length - 1
          ? `1px solid ${theme.palette.primary.grey}`
          : "";
      const wageringSourceName = combo?.wageringSourceNested?.shortName;
      const combination = combo?.combination?.[0];
      return (
        <ListItemCustom key={idx}>
          <ComboNumber sx={{ borderBottom }}>
            {wageringSourceName || combination || null}
          </ComboNumber>
          <ListItemTextCustom sx={{ borderBottom }}>
            {convertMoneyToDouble(combo.amount).toFixed(2)}
          </ListItemTextCustom>
        </ListItemCustom>
      );
    });
  };

  return (
    <Container>
      <TableContainer component={Box}>
        <List dense={true} sx={{ pt: 0, pb: 0 }}>
          {renderList()}
        </List>
      </TableContainer>
    </Container>
  );
};

export default MoneyMatrix;
