import React, { useRef, useState } from "react";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import { WAGERING_SOURCES_KEY } from "common/QueryKeys";
import { GridComponent } from "components/common/Grid";
import { useTranslation } from "react-i18next";
import { prism } from "@tsg/1st-grpc-web";
import WageringSourceUpsert from "components/transaction/WageringSource";
import WageringSourcesFilter from "components/common/WageringSourcesFilter";
import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import { usePrismWebContext } from "../../../components/common/context";
import Columns from "./column";
import { useQueryClient } from "@tanstack/react-query";
import { AlertBox } from "components/common/Dialog";

interface FilterValues {
  state?: prism.v1.transaction.WageringSourceEnums.State[];
}

export default function WageringSourcesListPage() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { organizationUnit, organization } = usePrismWebContext();
  const [organizationUnitName, setOrganizationUnitName] = organizationUnit;
  const [organizationName, setOrganizationName] = organization;

  const {
    useListWageringSources,
    useDisableWageringSource,
    useSuspendWageringSource,
    useReenableWageringSource
  } = useTransactionApi();
  const [filterValues, setFilterValues] = useState<FilterValues>();

  const actionMethod = useRef(null);

  const [errorMessage, setErrorMessage] = React.useState<string>(null);
  const [successMessage, setSuccessMessage] = React.useState<string>(null);
  const [openAlert, setOpenAlert] = React.useState<boolean>(false);

  const { data, isLoading, refetch, isRefetching } = useListWageringSources(
    WAGERING_SOURCES_KEY,
    {
      filter: [
        {
          parent:
            !organizationUnitName || organizationUnitName === "All Org Units"
              ? []
              : [organizationUnitName],
          org:
            !organizationName || organizationName === "All Organizations"
              ? []
              : [organizationName],
          ...filterValues
        }
      ],
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT
      }
    }
  );

  const actionCallback = successMessage => {
    return {
      onSuccess: response => {
        queryClient.invalidateQueries({ queryKey: [WAGERING_SOURCES_KEY] });
        setSuccessMessage(t(successMessage));
        setErrorMessage(null);
        setOpenAlert(true);
      },
      onError: errorResponse => {
        setErrorMessage(errorResponse?.message);
        setSuccessMessage(null);
        setOpenAlert(true);
      }
    };
  };

  const { mutate: mutateDisable } = useDisableWageringSource(
    actionCallback("wagering_source_disable_success_message")
  );
  const { mutate: mutateReenable } = useReenableWageringSource(
    actionCallback("wagering_source_reenable_success_message")
  );
  const { mutate: mutateSuspend } = useSuspendWageringSource(
    actionCallback("wagering_source_suspend_success_message")
  );

  const handleAction = (action: string, name: string) => {
    if (action === "disable") {
      mutateDisable({ name: name });
    }
    if (action === "reenable") {
      mutateReenable({ name: name });
    }
    if (action === "suspend") {
      mutateSuspend({ name: name });
    }
  };

  let wageringSources = [];
  if (data && data.wageringSources) {
    wageringSources = data.wageringSources;
  }

  const handleActionClick = (actionName, params) => {
    actionMethod.current(actionName, params.id);
  };

  const handleFilterChange = values => setFilterValues(values);

  return (
    <>
      <WageringSourcesFilter onFilterChange={handleFilterChange} />
      <GridComponent
        idProp={"name"}
        rowData={wageringSources}
        columnDefs={Columns(handleActionClick, t)}
        pageTitle={t("wagering_sources")}
        tooltipTitle={t("wagering_sources_create")}
        actionMethod={actionMethod}
        initialState={{
          sorting: {
            sortModel: [{ field: "displayName", sort: "asc" }]
          }
        }}
        loading={isLoading || isRefetching}
        editType="modal"
        rowHeight={"auto"}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
        handleAction={handleAction}
        displayNameInConfirmation
      >
        <WageringSourceUpsert />
      </GridComponent>
      <AlertBox
        successMessage={successMessage}
        errorMessage={errorMessage}
        open={openAlert}
        handleClose={() => setOpenAlert(false)}
      />
    </>
  );
}
