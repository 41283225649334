import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridFilterItem,
  GridRenderCellParams,
  GridValueGetterParams
} from "@mui/x-data-grid-pro";
import { prism } from "@tsg/1st-grpc-web";
import { convertSecondsToDateString } from "common/helper";
import CustomCell from "components/common/Grid/custom-cell";

interface ExtendedGridColDef extends GridColDef {
  getActions?: (params: unknown) => React.ReactElement[];
}

export const Columns = (handleActionClick, t): ExtendedGridColDef[] => {
  return [
    {
      field: "name",
      headerName: t("wagering_sources_name"),
      width: 250,
      hide: true,
      editable: false
    },
    {
      field: "parent",
      headerName: t("wagering_sources_parent"),
      width: 250,
      hide: false,
      editable: false
    },
    {
      field: "displayName",
      headerName: t("wagering_sources_display_name"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.displayName.value
    },
    {
      field: "shortName",
      headerName: t("wagering_sources_short_name"),
      width: 200,
      editable: false
    },
    {
      field: "foreignIds",
      headerName: t("wagering_sources_foreign_ids"),
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <CustomCell
          customValue={params.row.foreignIds?.map(
            fid => `${fid.type} ${fid.values}`
          )}
        >
          <List>
            {params.row.foreignIds.map(fid => (
              <>
                {fid.type}
                <List dense={true}>
                  {fid.values.map(value => (
                    <ListItem key={value} disableGutters disablePadding>
                      <ListItemText primary={value} sx={{ pl: 1 }} />
                    </ListItem>
                  ))}
                </List>
              </>
            ))}
          </List>
        </CustomCell>
      ),
      getApplyQuickFilterFn: (filterItem: GridFilterItem) => {
        if (!filterItem) {
          return null;
        }

        return (params: GridCellParams): boolean => {
          return params.value.find(fid =>
            fid.values.find(id => id.includes(filterItem))
          );
        };
      }
    },
    {
      field: "updateTime",
      headerName: t("updated_time"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.auditInfo?.updateTime);
      }
    },
    {
      field: "createTime",
      headerName: t("created_time"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return convertSecondsToDateString(params.row?.auditInfo?.createTime);
      }
    },
    {
      field: "updatedBy",
      headerName: t("updated_by"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.auditInfo?.updatedBy;
      }
    },
    {
      field: "createdBy",
      headerName: t("created_by"),
      width: 250,
      hide: true,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row?.auditInfo?.createdBy;
      }
    },
    {
      field: "state",
      headerName: t("wagering_source_status"),
      width: 200,
      valueGetter: (params: GridValueGetterParams) =>
        t(
          `wagering_source_state.${
            prism.v1.transaction.WageringSourceEnums.State[params.row.state]
          }`
        )
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      editable: false,
      getActions: params => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label={t("wagering_source_actions.edit")}
          onClick={() => handleActionClick("edit", params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<PauseCircleIcon />}
          label={t("wagering_source_actions.suspend")}
          onClick={() => handleActionClick("suspend", params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<DoneIcon />}
          label={t("wagering_source_actions.reenable")}
          onClick={() => handleActionClick("reenable", params)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<ClearIcon />}
          label={t("wagering_source_actions.disable")}
          onClick={() => handleActionClick("disable", params)}
          showInMenu
        />
      ]
    }
  ];
};
export default Columns;
