import React from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { MAXIMUM_RECORD_LIMIT } from "common/prism-constant";
import {
  MARKET_WAGERING_SOURCE_POOLS_KEY,
  MARKETS_KEY,
  WAGERING_SOURCES_KEY
} from "common/QueryKeys";
import { GridComponent } from "components/common/Grid";
import {
  APP_MARGIN_TOP,
  APP_PADDING_VERTICAL
} from "components/style/app-style";
import { useDataApi } from "hooks/api/data/DataAPI";
import { useTransactionApi } from "hooks/api/transaction/TransactionApi";
import { prism } from "@tsg/1st-grpc-web";
import Columns from "./column";
import MarketNameSearch from "components/transaction/MarketNetworkPool/MarketNameSearch";

const MarketWageringSourcePoolsPage = () => {
  const { t } = useTranslation();
  const { useListMarketWageringSourcePools, useListWageringSources } =
    useTransactionApi();
  const { useListMarkets } = useDataApi();

  const [filterValues, setFilterValues] = React.useState({
    marketId: ""
  });

  const {
    data: mwspData,
    isLoading: isLoadingMwsp,
    refetch: refetchMwsp,
    isRefetching: isRefetchingMwsp
  } = useListMarketWageringSourcePools(
    MARKET_WAGERING_SOURCE_POOLS_KEY,
    {
      filter: [
        {
          market: [filterValues?.marketId]
        }
      ],
      pagingOptions: {
        maxResults: MAXIMUM_RECORD_LIMIT
      }
    },
    {
      enabled: !!filterValues?.marketId
    }
  );

  const handleFilterChange = values => setFilterValues(values);

  const {
    data: marketsData,
    isLoading: isMarketsLoading,
    refetch: refetchMarkets,
    isRefetching: isMarketsRefetching
  } = useListMarkets(MARKETS_KEY, {
    filter: [
      {
        state: Object.keys(prism.v1.data.MarketEnums.State)
          .map(key => {
            if (
              [
                prism.v1.data.MarketEnums.State.STATE_INCOMPLETE,
                prism.v1.data.MarketEnums.State.STATE_CANCELLED
              ].every(type => prism.v1.data.MarketEnums.State[key] !== type)
            ) {
              return prism.v1.data.MarketEnums.State[key];
            }

            return null;
          })
          .filter(it => it !== null)
      }
    ],
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const markets = (marketsData?.markets || []).reduce((acc, cur) => {
    return {
      ...acc,
      [cur.name]: cur
    };
  }, {});

  const {
    data: wageringSourcesData,
    isLoading: isWageringSourcesLoading,
    refetch: refetchWageringSources,
    isRefetching: isWageringSourcesRefetching
  } = useListWageringSources(WAGERING_SOURCES_KEY, {
    pagingOptions: {
      maxResults: MAXIMUM_RECORD_LIMIT
    }
  });

  const wageringSources = wageringSourcesData?.wageringSources || [];

  const wageringSourcesMap = wageringSources.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.name]: cur
    }),
    {}
  );

  const treeData = React.useMemo(() => {
    if (isLoadingMwsp || isMarketsLoading) return [];

    return (
      mwspData?.marketWageringSourcePools?.map((pool: any) => {
        const market = marketsData.markets.find(
          (m: any) => m.id === pool.marketId
        );
        return {
          ...pool,
          marketDisplayName: market ? market.displayName : t("unknown"),
          marketSourceDisplayName: market ? market.sourceName : t("unknown"),
          marketNested: markets[pool.market],
          wageringSourceDisplayName:
            wageringSourcesMap[pool.sourceName]?.displayName?.value ||
            t("unknown")
        };
      }) || []
    );
  }, [mwspData, marketsData, t]);

  const isRefetching = [
    isMarketsRefetching,
    isRefetchingMwsp,
    isWageringSourcesRefetching
  ].some(Boolean);

  const isLoading = [
    isMarketsLoading,
    isLoadingMwsp,
    isRefetching,
    isWageringSourcesLoading
  ].some(Boolean);

  const refetch = () => {
    refetchMarkets();
    refetchMwsp();
    refetchWageringSources();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        maxHeight: {
          xs: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.xs * 2
          }px)`,
          md: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.md * 2
          }px)`,
          lg: `calc(100vh - ${APP_MARGIN_TOP}px - ${
            APP_PADDING_VERTICAL.lg * 2
          }px)`
        },
        "& .MuiDataGrid-root .MuiDataGrid-cell": {
          alignItems: "flex-start",
          "& .MuiListItemText-root:first-child": {
            marginTop: "0"
          }
        }
      }}
    >
      <MarketNameSearch
        onFilterChange={handleFilterChange}
        isLoading={isLoading || isRefetching}
      />
      <GridComponent
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column"
        }}
        idProp={"name"}
        rowData={treeData}
        columnDefs={Columns(t)}
        pageTitle={t("mwsp_title")}
        initialState={{
          sorting: {
            sortModel: [{ field: "marketDisplayName", sort: "asc" }]
          },
          rowGrouping: {
            model: ["marketSourceDisplayName"]
          }
        }}
        rowHeight={"auto"}
        disableAddButton={true}
        loading={isLoading}
        refreshButton={{
          onRefresh: refetch,
          isRefreshingData: isRefetching
        }}
        treeData={false}
        disableCellMouseEvents={true}
      >
        <></>
      </GridComponent>
    </Box>
  );
};

export default MarketWageringSourcePoolsPage;
